.activity_item {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px rgb(60,54,79);
  overflow: hidden;
}

a.activity_item {
  text-decoration: none;
}
a.activity_item:hover {
  background: rgba(255,255,255,0.15);
}

.replies {
  padding-left: 24px;
  background: rgba(255,255,255,0.15);
}
.replies .activity_item{
  background: var(--fg);
}

.acitivty_main {
  padding: 16px;
}
.activity_item:last-child {
  border-bottom: none;
}

.activity_item .activity_actions {
  display: flex;
  align-items: flex-end;
  padding-top: 16px;
  padding-left: 60px;
}

.activity_item .activity_actions .action{
  flex-grow: 1;
  cursor: pointer;
  padding: 8px;
  border-radius: 999px;
  display: flex;
  flex-direction: row;
  gap: 12px
}

.activity_item .activity_actions .action .counter{
  color: rgba(255,255,255,0.8);
  font-weight: 800;
}

.activity_item .activity_actions .icon{
  height: 18px;
  width: 18px;
  fill: #fff;
}

.activity_item .activity_actions .action:hover {
  background: rgba(149,0,255,0.2);
}

.activity_item .activity_actions .action:hover .icon {
  fill: rgb(149,0,255);
}


.activity_item.expanded .activity_content {
  display: flex;
}
.activity_item.expanded .activity_content .activity_identity{
  flex-grow: 1;
}