.search_field input[type='text'] {
  border: solid 1px var(--field-border);
  background: var(--field-bg);
  padding: 16px;
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  outline: none;
  color: #fff;
}
.search_field input[type='text']:focus {
  border: solid 1px var(--field-border-focus)
}