article.recover-article .recover-info .logo {
  width: 140px;
  height: 140px;
  fill: #2021246c;
}

article.recover-article {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #8819f5;
}

article.recover-article .recover-wrapper {
  background: #202124;
  width: 560px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
article.recover-article form {
  padding: 48px;
  flex-grow: 1;
}

article.recover-article label {
  padding: 4px 0px;
  display: block;
  color: rgba(255,255,255,0.6);
}

article.recover-article input[type='text'],
article.recover-article input[type='password'] {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  outline: none;
  display: block;
  outline: none;
  resize: none;
  width: 100%;
  padding: 16px;
  border: solid 1px #555555;
  background: #1f1f1f;
  color: #fff;
}

.field.password {
  margin-top: 16px;
}

article.recover-article input[type='text']:focus ,
article.recover-article input[type='password']:focus {
  border: solid 1px rgb(149,0,255,1);
}

article.recover-article .submit {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

article.recover-article  h2 {
  text-align: center;
  color: #fff;
}



article.recover-article button[type='submit'] {
  font-weight: 800;
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 18px 28px;
  font-size: 16px;
  background: rgba(149,0,255,1);
  color: #fff;
}