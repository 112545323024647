article.signin-article .signin-info {
  flex-grow: 1;
  padding-left: 12px;
}

article.signin-article .signin-info .logo {
  width: 140px;
  height: 140px;
  fill: #2021246c;
}

article.signin-article {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  background: #8819f5;
}

article.signin-article .signin-wrapper {
  background: #202124;
  width: 760px;
  display: flex;
  padding-top: 120px;
  flex-direction: column;
}
article.signin-article form {
  padding: 0px 120px;
  flex-grow: 1;
}

article.signin-article label {
  padding: 4px 0px;
  display: block;
  color: rgba(255,255,255,0.6);
}

article.signin-article input[type='text'],
article.signin-article input[type='password'] {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  outline: none;
  display: block;
  outline: none;
  resize: none;
  width: 100%;
  padding: 16px;
  border: solid 1px var(--field-border);
  background: var(--field-bg);
  color: #fff;
}

.field.password {
  margin-top: 16px;
}

article.signin-article input[type='text']:focus ,
article.signin-article input[type='password']:focus {
  border: solid 1px var(--field-border-focus);
}

article.signin-article .submit {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

article.signin-article  h2 {
  text-align: center;
  color: #fff;
}

article.signin-article a.forgot-link {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}


article.signin-article button[type='submit'] {
  font-weight: 800;
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 18px 28px;
  font-size: 16px;
  background: rgba(149,0,255,1);
  color: #fff;
}

.dont-have-an-account {
  background: #252525;
  padding: 24px;
  color: rgba(255,255,255,0.6);
  gap: 8px;
  display: flex;
  justify-content: center;
}
.dont-have-an-account a {
  color: rgba(149,0,255,1);
  text-decoration: none;
  font-weight: 600;
}