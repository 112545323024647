form.activity_form {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

form.activity_form textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  outline: none;
  display: block;
  outline: none;
  resize: none;
  width: 100%;
  height: 140px;
  padding: 16px;
  border: solid 1px rgba(149,0,255,0.1);
  background: rgba(149,0,255,0.1);
  color: #fff;
}

form.activity_form textarea:focus {
  border: solid 1px rgb(149,0,255,1);
}

form.activity_form .submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
}

form.activity_form button[type='submit'] {
  font-weight: 800;
  outline: none;
  border: none;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 38px;
  padding: 10px 20px;
  font-size: 16px;
  margin-left: 12px;
  background: rgba(149,0,255,1);
  color: #fff;
}

form.activity_form .count {
  color: rgba(255,255,255,0.3)
}

form.activity_form .count.err {
  color: rgb(255, 0, 0)
}

form.activity_form .expires_at_field {
  display: flex;
  gap: 12px;
  position: relative;
  border-left: solid 1px rgba(149,0,255,0.7);
}

form.activity_form .expires_at_field .icon {
  position: absolute;
  top: 12px;
  left: 8px;
  fill: #fff;
  width: 14px;
  height: 14px;
  z-index: 2;
}

form.activity_form select {
  width: 100%;
  outline: none;
  width: 120px;
  height: 38px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  padding: 10px 8px;
  padding-left: 28px;
  border: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #fff;
  background-color: rgba(149,0,255,0.8);
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  position: relative;
  z-index: 1;
}