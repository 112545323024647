.trendings {
  display: flex;
  flex-direction: column;
  background: #000;
  border-radius: 8px;
  margin-top: 24px;
}

.trendings .trendings-title {
  font-size: 16px;
  color: #fff;
  padding: 16px;
  border-bottom: solid 1px rgb(31,36,49);
}

.trendings a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  padding: 16px;
}

.trendings a span {
  display: block;
}

.trendings a span.count {
  font-size: 13px;
  color: rgba(255,255,255,0.5)
}