form.message_form {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

form.message_form textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  outline: none;
  display: block;
  outline: none;
  resize: none;
  width: 100%;
  height: 140px;
  padding: 16px;
  border: solid 1px rgba(149,0,255,0.1);
  background: rgba(149,0,255,0.1);
  color: #fff;
}

form.message_form textarea:focus {
  border: solid 1px rgb(149,0,255,1);
}

form.message_form .submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
}

form.message_form button[type='submit'] {
  font-weight: 800;
  outline: none;
  border: none;
  border-radius: 4px;
  height: 38px;
  padding: 10px 20px;
  font-size: 16px;
  background: rgba(149,0,255,1);
  color: #fff;
  margin-left: 12px;
}

form.message_form .count {
  color: rgba(255,255,255,0.3)
}

form.message_form .count.err {
  color: rgb(255, 0, 0)
}
